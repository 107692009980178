<template>
	<div class="governmentss">
		<div class="container">
			<div class="header">
				<div class="weather">
					<p>
						<i></i>
						晴
					</p>
					<p><span>18-32℃</span></p>
				</div>
				<div class="title">
					<i class="line line1"></i>
					<i class="line line2"></i>
					<p>
						<span>万</span>
						<span>达</span>
						<span>开</span>
						<span>科</span>
						<span>技</span>
						<span>创</span>
						<span>新</span>
						<span>数</span>
						<span>据</span>
						<span>云</span>
						<span>平</span>
						<span>台</span>
					</p>
				</div>
				<div class="dateTime">
					<p>{{ nowDate }}</p>
					<p>{{ nowTime }}</p>
				</div>
			</div>
			<div class="main">
				<div class="box leftCon">
					<!-- 左一 -->
					<div class="tabs one" style="height: 82px;">
						<div class="lines">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
						<div class="tabCon">
							<div class="headline">
								<span></span>
								<span></span>
								<h3>国家高新技术企业、科技型中小企业展示</h3>
								<span></span>
								<span></span>
							</div>
							<vue-seamless-scroll :data="listData1" :class-option="optionHover" class="list" style="height: 65px;">
								<ul class="item">
									<li v-for="item in listData1">
										<i></i>
										<p>{{ item.name }}</p>
										<span>查看详情 &gt;</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 左二 -->
					<div class="tabs two" style="height: 78px; margin: 6px 0;">
						<div class="lines">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
						<div class="tabCon">
							<div class="headline">
								<span></span>
								<span></span>
								<h3>科技成果展示</h3>
								<span></span>
								<span></span>
							</div>
							<vue-seamless-scroll :data="listData2" :class-option="optionHover" class="list" style="height: 60px;">
								<ul class="item">
									<li v-for="item in listData2">
										<div class="twoTit">
											<i></i>
											<p>{{ item.name }}</p>
										</div>
										<span>所属地市：{{ item.city }}</span>
										<span>研发单位：{{ item.education }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 左三 -->
					<div class="tabs" style="height: 96px;">
						<div class="lines">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
						<div class="tabCon">
							<div class="headline">
								<span></span>
								<span></span>
								<h3>载体分布情况</h3>
								<span></span>
								<span></span>
							</div>
							<div class="list" style="height: 77px;">
								<el-tabs class="select" v-model="tabValue" @tab-click="handleClick" style="height: 24px; margin-top: 5px;">
									<el-tab-pane label="众创空间" name="0"></el-tab-pane>
									<el-tab-pane label="孵化器" name="1"></el-tab-pane>
								</el-tabs>
								<div id="ECharts1" ref="ECharts1" :style="{ width: '100%', height: '75%' }"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="box centerCon"><div id="ECharts2" ref="ECharts2" style="width: 300px; height: 260px;"></div></div>
				<div class="box rightCon">
					<!-- 右一 -->
					<div class="tabs two" style="height: 80px;">
						<div class="lines">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
						<div class="tabCon">
							<div class="headline">
								<span></span>
								<span></span>
								<h3>科技服务资源</h3>
								<span></span>
								<span></span>
							</div>
							<vue-seamless-scroll :data="listData3" :class-option="optionHover" class="list" style="height: 60px;">
								<ul class="item">
									<li v-for="item in listData3">
										<div class="twoTit">
											<i></i>
											<p>{{ item.name }}</p>
										</div>
										<span>所属地市：{{ item.city }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 右二 -->
					<div class="tabs two" style="height: 70px; margin: 12px 0;">
						<div class="lines">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
						<div class="tabCon">
							<div class="headline">
								<span></span>
								<span></span>
								<h3>技术合同登记情况</h3>
								<span></span>
								<span></span>
							</div>
							<vue-seamless-scroll :data="listData4" :class-option="optionHover" class="list" style="height: 50px;">
								<ul class="item">
									<li v-for="item in listData4">
										<div class="twoTit">
											<i></i>
											<p>{{ item.name }}</p>
										</div>
										<span>技术合同登记额：{{ item.education }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
					<!-- 右三 -->
					<div class="tabs two" style="height: 98px;">
						<div class="lines">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
						<div class="tabCon">
							<div class="headline">
								<span></span>
								<span></span>
								<h3>研发投入情况</h3>
								<span></span>
								<span></span>
							</div>
							<div class="list" style="height: 79px;">
								<div class="select" style="height: 40px; display: none;">
									<el-select v-model="cityValue" @change="selectClass">
										<el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</div>
								<div id="ECharts3" ref="ECharts3" :style="{ width: '100%', height: '100%' }"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { maps } from '../../../common/js/dazhou';
import { wandlist, wandblist, wandzhong, wandfuhua, wanddlist, wandalist, wandclist, wandkeyan } from '../../../common/js/api';
export default {
	name: 'enterpriseCloud2',
	components: {},
	data() {
		return {
			nowDate: null, //存放年月日变量
			nowTime: null, //存放时分秒变量
			timer: '', //定义一个定时器的变量
			currentTime: new Date(), // 获取当前时间
			listData1: [],
			listData2: [],
			listData3: [],
			listData4: [],
			tabValue: '0',
			cityData: [],
			// cityData: [{ id: 1, value: '达州市', label: '达州市' }, { id: 2, value: '万州区', label: '万州区' }, { id: 3, value: '开州区', label: '开州区' }],
			cityValue: '达州市',
			maps: '',
			mark: 0
		};
	},
	created() {
		// 获取时间
		this.timer = setInterval(this.getTime, 1000);
		// 国家技术
		this.wandlist();
		// 科技成果
		this.wandblist();
		// 载体分布情况 - init
		this.loadData1(this.tabValue);
		// 科技服务
		this.wandalist();
		// 技术合同
		this.wandclist();
		// 研发投入情况 - init
		this.loadData3(this.cityValue);
	},
	computed: {
		optionHover() {
			return {
				step: 0.3, //速度，值越大，速度越快
				hoverStop: true, //鼠标悬停效果，false为关闭该效果
				// singleHeight: 26, //单行停顿
				// waitTime: 2500 //单行停顿的时间
				limitMoveNum: 1 // 开始无缝滚动的数据量
			};
		}
	},
	mounted() {
		// 获取时间
		this.getTime();
		// 地图 - init
		this.loadData2();
	},
	methods: {
		// 获取时间
		getTime() {
			const date = new Date();
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const hour = date.getHours();
			const minute = date.getMinutes();
			const second = date.getSeconds();
			const str = '';
			if (this.hour > 12) {
				this.hour -= 12;
				// this.str = ' PM';
				this.str = ' ';
			} else {
				// this.str = ' AM';
				this.str = ' ';
			}
			this.month = check(month);
			this.day = check(day);
			this.hour = check(hour);
			this.minute = check(minute);
			this.second = check(second);
			function check(i) {
				const num = i < 10 ? '0' + i : i;
				return num;
			}
			this.nowDate = year + '年' + this.month + '月' + this.day + '日';
			this.nowTime = this.hour + ':' + this.minute + ':' + this.second + this.str;
		},
		// 国家技术
		wandlist() {
			wandlist()
				.then(res => {
					this.listData1 = res.data;
				})
				.catch(() => {
					this.listData1 = [];
				});
		},
		// 科技成果
		wandblist() {
			wandblist()
				.then(res => {
					this.listData2 = res.data;
				})
				.catch(() => {
					this.listData2 = [];
				});
		},
		// 载体分布情况 - data
		loadData1(tabValue) {
			var data = [];
			if (tabValue == '0') {
				wandzhong()
					.then(res => {
						res.data.map(item => {
							data.push({
								value: Number(item.num),
								name: item.name
							});
						});
						this.$nextTick(function() {
							// 众创空间 - 图表
							this.ECharts1(data);
						});
					})
					.catch(() => {
						data = [];
					});
			} else if (tabValue == '1') {
				wandfuhua()
					.then(res => {
						res.data.map(item => {
							return (item.value = item.num);
						});
						data = res.data;
						this.$nextTick(function() {
							// 孵化器 - 图表
							this.ECharts1(data);
						});
					})
					.catch(() => {
						data = [];
					});
			}
		},
		// 载体分布情况 - 选择类型
		handleClick(tab, event) {
			this.tabValue = tab.index;
			this.loadData1(this.tabValue);
		},
		// 载体分布情况 - 图表
		ECharts1(data) {
			let ECharts = this.$echarts.init(this.$refs.ECharts1);
			ECharts.setOption({
				tooltip: {},
				color: ['#5087ec', '#68bbc4', '#58a55c', '#f2bd42', '#ee752f', '#d95040', '#5087ec', '#68bbc4', '#58a55c', '#f2bd42'],
				legend: {
					show: false
				},
				label: {
					color: '#fff'
				},
				top: 40,
				series: [
					{
						type: 'pie',
						radius: ['50%', '70%'],
						roundCap: true,
						itemStyle: {
							borderRadius: 40
						},
						emphasis: {
							label: {
								show: true,
								position: 'center',
								textStyle: {
									fontSize: '10',
									fontWeight: 'bold'
								}
							}
						},
						labelLine: {
							show: true,
							length: 10,
							length2: 15
						},
						data: data
					}
				]
			});
			window.addEventListener('resize', function() {
				ECharts.resize();
			});
		},
		// 地图 - data
		loadData2() {
			this.maps = maps();
			this.$echarts.registerMap('dazhou', this.maps);
			let ECharts = this.$echarts.init(this.$refs.ECharts2);
			let data = [];
			wanddlist()
				.then(res => {
					res.data.forEach(item => {
						data.push({
							name: item.name,
							value1: '知识产权数：' + item.city,
							value2: '技术合同登记额：' + item.education + '万元'
						});
					});
					ECharts.setOption({
						tooltip: {
							trigger: 'item',
							formatter: function(params) {
								var toolTiphtml = '';
								for (var i = 0; i < data.length; i++) {
									if (params.name == data[i].name) {
										toolTiphtml += data[i].value1 + '<br/>' + data[i].value2;
									}
								}
								return toolTiphtml;
							},
							textStyle: {
								color: '#cdb10d',
								fontWeight: 'bold'
							}
						},
						series: [
							{
								type: 'map',
								map: 'dazhou',
								label: {
									show: true,
									color: '#fff'
								},
								zoom: 1.2,
								itemStyle: {
									areaColor: '#1f71b0',
									borderColor: '#fff'
								},
								emphasis: {
									label: { color: '#000' },
									itemStyle: {
										areaColor: '#02cde6'
									}
								},
								data: data,
								select: {
									label: { color: '#f33' }
								}
							}
						]
					});
					window.addEventListener('resize', function() {
						ECharts.resize();
					});
				})
				.catch(() => {
					data = [];
				});
		},
		// 科技服务
		wandalist() {
			wandalist()
				.then(res => {
					this.listData3 = res.data;
				})
				.catch(() => {
					this.listData3 = [];
				});
		},
		// 技术合同
		wandclist() {
			wandclist()
				.then(res => {
					this.listData4 = res.data;
				})
				.catch(() => {
					this.listData4 = [];
				});
		},
		// 研发投入情况 - data
		loadData3(cityValue) {
			var data = [];
			this.cityData = [];
			wandkeyan()
				.then(res => {
					res.data.map(item => {
						this.cityData.push({
							id: item.id,
							value: item.city,
							label: item.city
						});
						if (cityValue == item.city) {
							data = [
								{
									name: '研发投入总值',
									type: 'bar',
									barGap: 0,
									color: '#02cde6',
									data: [item.onenum, item.twonum, item.therenum],
									barWidth: '28%'
								},
								{
									name: '研发投入占GDP比重',
									type: 'bar',
									color: '#ff5e2b',
									data: [item.onenums, item.twonums, item.therenums],
									barWidth: '28%'
								}
							];
						}
					});
					this.$nextTick(function() {
						// 研发投入情况 - 图表
						this.ECharts3(data);
					});
				})
				.catch(() => {
					data = [];
				});
		},
		// 研发投入情况 - 选择城市
		selectClass(value) {
			this.cityValue = value;
			this.loadData3(this.cityValue);
		},
		// 研发投入情况 - 图表
		ECharts3(data) {
			let ECharts = this.$echarts.init(this.$refs.ECharts3);
			ECharts.setOption({
				// legend: {
				// 	textStyle: {
				// 		color: '#02cde6'
				// 	}
				// },
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					textStyle: {
						color: '#666666'
					},
					padding: 10,
					backgroundColor: '#fff'
				},
				xAxis: [
					{
						type: 'category',
						data: ['2018', '2019', '2020'],
						axisPointer: {
							type: 'shadow'
						},
						axisLine: {
							lineStyle: {
								color: '#6e7079'
							}
						},
						axisLabel: {
							color: '#02cde6'
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						axisLine: {
							lineStyle: {
								color: 'transparent'
							}
						},
						axisLabel: {
							color: '#02cde6'
						}
					}
				],
				series: data
			});
			window.addEventListener('resize', function() {
				ECharts.resize();
			});
		}
	},
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
		}
	}
};
</script>

<style lang="less" scoped>
.governmentss {
	width: 730px;
	height: 324px;
	// max-width: 100%;
	// width: 100%;
	// height: 100%;
	margin: 0 auto;
	background: url(../../../assets/img/zhengfuyun/enterpriseBg.png) no-repeat center center;
	background-size: cover;
	background-attachment: fixed;
	.container {
		width: 95%;
		padding: 6px 0;
		margin: 0 auto;
		box-sizing: border-box;
		.header {
			width: 100%;
			padding: 0 6%;
			height: 24px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(4, 26, 64, 100);
			box-shadow: 0px 0px 6px 0px rgba(162, 161, 161, 100);
			.weather {
				p {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					font-size: 10px;
					color: #fff;
					line-height: 1.2;
					i {
						width: 14px;
						height: 14px;
						margin-right: 8px;
						background: url(../../../assets/img/zhengfuyun/sunny.svg);
					}
					span {
						font-size: 12px;
						letter-spacing: 3px;
					}
				}
			}
			.title {
				width: 59%;
				height: 24px;
				padding: 0 4% 0 3%;
				position: relative;
				.line {
					height: 2px;
					background-color: #02cde6;
				}
				.line1 {
					width: 40%;
					position: absolute;
					top: -1.5px;
					left: 30%;
				}
				.line2 {
					width: 24%;
					position: absolute;
					left: 38%;
					bottom: -1.5px;
				}
				p {
					height: 24px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 12px;
					color: #02cde6;
					line-height: 24px;
					font-weight: bold;
				}
			}
			.dateTime {
				width: 160px;
				p {
					font-size: 12px;
					color: #fff;
					line-height: 14px;
					letter-spacing: 4px;
				}
			}
		}
		.main {
			width: 96%;
			margin: 10px auto;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			.box {
				.tabs {
					position: relative;
					border: 1px solid #2480b4;
					background-color: #051d3f;
					box-shadow: 0px 2px 6px 0px rgba(36, 128, 179, 100);
					.lines {
						width: calc(100% + 6px);
						height: calc(100% + 6px);
						i {
							display: inline-block;
							width: 5px;
							height: 5px;
							position: absolute;
							z-index: 5;
							&:nth-child(1) {
								top: -1.5px;
								left: -1.5px;
								border-top: 1.5px solid #02cde6;
								border-left: 1.5px solid #02cde6;
							}
							&:nth-child(2) {
								top: -1.5px;
								right: -1.5px;
								border-top: 1.5px solid #02cde6;
								border-right: 1.5px solid #02cde6;
							}
							&:nth-child(3) {
								bottom: -3px;
								left: -1.5px;
								border-left: 1.5px solid #02cde6;
								border-bottom: 1.5px solid #02cde6;
							}
							&:nth-child(4) {
								bottom: -3px;
								right: -1.5px;
								border-right: 1.5px solid #02cde6;
								border-bottom: 1.5px solid #02cde6;
							}
						}
					}
					.tabCon {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 10;
						.headline {
							padding: 0 5%;
							height: 16px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							background-color: #051d3f;
							span {
								display: inline-block;
								width: 7px;
								height: 7px;
								&:nth-child(1),
								&:nth-child(2) {
									transform: rotate(45deg);
									-ms-transform: rotate(45deg);
									-moz-transform: rotate(45deg);
									-webkit-transform: rotate(45deg);
									-o-transform: rotate(45deg);
								}
								&:nth-child(4),
								&:nth-child(5) {
									transform: rotate(225deg);
									-ms-transform: rotate(225deg);
									-moz-transform: rotate(225deg);
									-webkit-transform: rotate(225deg);
									-o-transform: rotate(225deg);
								}
								&:nth-child(1),
								&:nth-child(4) {
									border-top: 2px solid rgba(45, 143, 219, 0.63);
									border-right: 2px solid rgba(45, 143, 219, 0.63);
								}
								&:nth-child(2),
								&:nth-child(5) {
									border-top: 2px solid #9acef7;
									border-right: 2px solid #9acef7;
								}
							}
							h3 {
								width: 85%;
								height: 24px;
								padding: 0 2%;
								font-size: 12px;
								line-height: 24px;
								color: #237dbf;
								text-align: center;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
						.list {
							background-color: rgba(9, 48, 104, 0.82);
							overflow: hidden;
							ul {
								width: 100%;
								height: 100%;
								li {
									width: 91.6%;
									margin: 0 auto;
									border-bottom: 1px solid #294062;
									i {
										display: inline-block;
										width: 4px;
										height: 4px;
										margin-left: 4px;
										border-radius: 50%;
										background-color: #02cde6;
									}
									p {
										width: 50%;
										font-size: 12px;
										font-weight: bold;
										text-overflow: ellipsis;
										white-space: nowrap;
										overflow: hidden;
									}
									span {
										font-size: 10px;
									}
								}
							}
							.select {
								display: flex;
								align-items: center;
								justify-content: flex-end;
								/deep/ .el-select {
									margin-right: 8px;
									.el-input {
										.el-input__inner {
											width: 80px;
											height: 30px;
											padding: 0 5px;
											font-size: 14px;
											color: #237dbf;
											line-height: 30px;
											border: 1px solid #237dbf;
											border-radius: 7px;
											background-color: rgba(9, 48, 104, 0.82);
										}
										.el-input__suffix {
											color: #237dbf;
											.el-input__icon {
												line-height: 30px;
											}
										}
									}
								}
								/deep/.el-tabs__header {
									width: 120px;
									margin: 0 15px 0 0;
									.el-tabs__active-bar {
										display: none;
									}
									.el-tabs__item {
										height: 14px;
										padding: 0 8px;
										font-size: 12px;
										color: #fff;
										line-height: 14px;
									}
									#tab-0 {
										border-right: 1px solid #fff;
									}
									.el-tabs__item.is-active {
										color: #02cde6;
									}
									.el-tabs__nav-wrap::after {
										height: 0;
									}
								}
							}
						}
					}
				}
				.one {
					li {
						height: 28px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						p {
							width: 83%;
							color: #02cde6;
							line-height: 28px;
						}
						span {
							color: #02cde6;
						}
					}
				}
				.two {
					li {
						padding-bottom: 3px;
						.twoTit {
							height: 32px;
							display: flex;
							align-items: center;
							p {
								width: 96%;
								color: #90d0ff;
								text-indent: 4px;
							}
						}
						span {
							display: block;
							font-size: 12px;
							color: #237cbe;
							line-height: 1.5;
						}
					}
				}
			}
			.leftCon,
			.rightCon {
				width: 26%;
			}
			.centerCon {
				width: 47%;
				height: 260px;
			}
		}
	}
}
/deep/.el-select-dropdown__wrap {
	margin-right: 0;
}
</style>
<style>
#ECharts2 {
	div {
		width: 100% !important;
	}
	canvas {
		width: 100%;
	}
}
</style>
